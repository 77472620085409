import { Buffer } from 'buffer';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

interface QSType {
  jwt: string;
  hash: RegExpMatchArray | null;
  returnTo: string;
  getRedirectHash: () => string;
}

export default function useQueryString(): QSType {
  const router = useRouter()

  const returnTo = useMemo(
    () => router.query.returnTo || '/',
    [router.query]
  ) as string;

  const hash = useMemo(() => {
    return router.asPath.match(/#([a-z0-9]+)/gi)
  }, [router.asPath])

  const jwtObject = useMemo(() => {
    if (!hash) return undefined;
    return JSON.parse(Buffer.from(hash[0], 'base64')?.toString());
  }, [hash]);

  const jwt = jwtObject?.jwt;

  const getRedirectHash = useCallback(() => {
    const queryParams = router.query

    let returnPath = '';

    if (queryParams?.from) {
      returnPath = `returnTo=${queryParams.from}`;
    }

    const redirectHash = Buffer.from(
      JSON.stringify({
        ret: `//${window.location.host}/auth${returnPath ? `?${returnPath}` : ''}`,
        rts: {},
        signin: true,
      })
    ).toString('base64');

    return redirectHash;
  }, [router.query]);

  return { jwt, hash, returnTo, getRedirectHash };
}

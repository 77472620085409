import { Link } from '@chakra-ui/react';
import React from 'react';
import NextLink from 'next/link';
import { useColorScheme } from '../../theme/chakra/colors';

interface InternalLinkProps extends React.PropsWithChildren{
  text?: string;
  href: string;
}

const InternalLink: React.FC<InternalLinkProps> = ({
  children /** @type {React.Children} */,
  text /** @type {string} text href display, overridden by children */,
  href /** @type {string} internal route */,
}) => {
  const linkColors = useColorScheme('makeLinkColors');
  return (
    <Link {...linkColors} fontWeight="medium" as={NextLink} href={href}>
      {children ? children : text}
    </Link>
  );
};

export default InternalLink;

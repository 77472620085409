import { extendTheme } from '@chakra-ui/react';

// extend the default chakra theme
const theme = extendTheme({
  config: {
    // light/dark
    initialColorMode: 'light', // try to guess from system value but let the app override
    useSystemColorMode: false, // if true, would use the system value only and not allow toggling
    // https://github.com/chakra-ui/chakra-ui/issues/2238#issuecomment-708232611
  },
  // base font Moderat
  fonts: {
    body: `Moderat, 'Helvetica Neue', Helvetica, Arial, sans-serif`,
    heading: `Moderat, 'Helvetica Neue', Helvetica, Arial, sans-serif`,
  },
  colors: {
    gray: {
      100: '#F7F7F7',
      400: '#CBCBCB',
    },
    blue: {
      500: '#2E8BD2',
    },
  },
});

export default theme;

import { Image, ImageProps } from '@chakra-ui/react';
import React from 'react';
import useAppLanguage from '../../../../hooks/useAppLanguage';
import mark from '../../../../public/images/logos/logo-mark.png';

const LogoMark: React.FC<ImageProps> = ({ src, objectFit, maxWidth, maxHeight, ...rest }) => {
  const { translate } = useAppLanguage();
  return (
    <Image
      src={mark.src}
      objectFit="contain"
      maxWidth="100%"
      maxHeight="100%"
      alt={translate('Wonderschool Logo')}
      {...rest}
    />
  );
};
export default LogoMark;

import { Image, ImageProps, useColorModeValue } from '@chakra-ui/react';
import logoWordDark from '../../../../public/images/logos/logo-word.png';
import logoWordLight from '../../../../public/images/logos/logo-word-white.png';
import useAppLanguage from '../../../../hooks/useAppLanguage';
import React from 'react';

const Logo: React.FC<ImageProps> = ({ src, objectFit, maxWidth, maxHeight, ...rest }) => {
  const { translate } = useAppLanguage();
  const logoByColor = useColorModeValue(logoWordDark, logoWordLight);

  return (
    <Image
      src={logoByColor.src}
      objectFit="contain"
      maxWidth="100%"
      maxHeight="100%"
      alt={translate('Wonderschool Logo')}
      {...rest}
    />
  );
};
export default Logo;

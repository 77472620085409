import useAuth from '../hooks/useAuth';
import useQueryString from '../hooks/useQueryString';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { PUBLIC_ROUTES } from './routes';
import CustomTokenAuth from '../components/Auth/CustomTokenAuth';

function AuthCheck({ children }: React.PropsWithChildren) {

  const router = useRouter();
  const { currentUser } = useAuth();
  const { hash } = useQueryString();

  useEffect(() => {
    if (router.pathname.includes('401')) {
      router.replace(PUBLIC_ROUTES.NOT_FOUND)
    }
    
    if (!currentUser?.uid && hash && router.pathname != PUBLIC_ROUTES.AUTH) {
      const authRouteWithHash = `${PUBLIC_ROUTES.AUTH}${hash?.[0] || ''}`
      router.replace(authRouteWithHash)
    }
  }, [currentUser, router])

  // try signin, if fail -> send them away
  if (!currentUser?.uid && hash && router.pathname == PUBLIC_ROUTES.AUTH) {
    return (
      <CustomTokenAuth />
    )
  }

  // if signed in, let them view
  return (
    <>
      {children}
    </>
  );
};

export default AuthCheck;

// Initializes Firebase
import '../firebase'

import { AppProps } from "next/app"
import MainLayout from "../components/PageLayout/MainLayout"
import ApplicationProvider from '../providers/ApplicationProvider'
import AuthCheck from '../routes/AuthCheck'

export default function App({ Component, pageProps }: AppProps) {
  return (
    <ApplicationProvider>
      <AuthCheck>
        <MainLayout>
          <Component {...pageProps} />
        </MainLayout>
      </AuthCheck>
    </ApplicationProvider>
  )
}

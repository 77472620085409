import React from 'react';
import { Box, Flex, Spacer, Stack } from '@chakra-ui/react';
import InternalLink from '../../components/Links/InternalLink';
import { useColorScheme } from '../../theme/chakra/colors';
import { HEADER_SIZE, LOGO_WIDTH, PADDING } from '../../theme/chakra/layout';
import LeftLogo from './Header/LeftLogo';
import RightMenu from './Header/RightMenu';

const MainLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const bgColors = useColorScheme('makeBackgroundColors');

  return (
    <Flex minH="100vh" direction="column" align="center" justify="center" h="full" textAlign="center" {...bgColors}>
      <Stack direction="row" padding={PADDING} w="100%" bg="white">
        <InternalLink href="/">
          <LeftLogo />
        </InternalLink>
        <RightMenu />
      </Stack>

      <Spacer
        display={{
          base: 'none',
          sm: 'block',
        }}
      />

      <Box
        w="100%"
        h="100%"
        marginTop={{
          base: '4', // unique for narrow viewport
          sm: '0',
        }}
        padding={PADDING}
      >
        {children ?? null}
      </Box>

      <Spacer />
      <Box padding={PADDING} w="100%" minH={HEADER_SIZE}>
        <Box w={LOGO_WIDTH} h={HEADER_SIZE} /* fake logo spacer*/ />
      </Box>
    </Flex>
  );
};

export default MainLayout;

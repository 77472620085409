import { useEffect } from 'react';
import { Text } from '@chakra-ui/react';

import { useRouter } from 'next/router';
import useAuth from '../../hooks/useAuth';
import useQueryString from '../../hooks/useQueryString';

import { getErrorMessage } from '../../utils/error';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '../../routes/routes';

export default function CustomTokenAuth() {
  const router = useRouter();
  const { currentUser, authContextJwt, setJwtInContext, signInWithToken } = useAuth();

  // Get the jwt and returnTo path from the URL hash.
  const { jwt, returnTo } = useQueryString();

  useEffect(() => {
    // do this first
    if (jwt && !authContextJwt) {
      setJwtInContext(jwt)
    }

    // ready to redirect
    if (currentUser?.uid) {
      router.push(PRIVATE_ROUTES.ONBOARDING)
      return;
    }

    if (!jwt?.length && !currentUser) {
      router.replace(PUBLIC_ROUTES.SIGN_IN)
      return;
    }

    (async () => {
      try {
        let redirectTo = returnTo;

        // Sign in user with the jwt.
        await signInWithToken(jwt);
        
        // If no returnTo is provided, redirect to home.
        if (!returnTo?.length) redirectTo = PRIVATE_ROUTES.ONBOARDING;
        
        // Redirect to the returnTo path.
        router.replace(redirectTo);

      } catch (error) {
        const { message } = getErrorMessage(error);
        console.log(message);
      }
    })();
  }, [currentUser, jwt, router, returnTo, signInWithToken, setJwtInContext, authContextJwt]);

  return <Text my="10">Authenticating....</Text>;
}


export enum PUBLIC_ROUTES {
  SIGN_IN = '/sign-in',
  AUTH = '/auth',
  NOT_FOUND = '/not-authorized',
};

export enum PRIVATE_ROUTES {
  ONBOARDING = '/onboarding',
  ONBOARDING_FINALIZE = '/onboarding-finalize',
  PAYMENT_SETUP_CONFIRMATION = '/payment-setup=confirmation',
  PAYMENT_SETUP = '/payment-setup',
  PRICING_TIER_SELECTION = '/pricing-tier-selection'
};
